export const grammarDef = `
  Coda {
    Expr = 
      | "#" any* -- comment
      | "OR" -- or
      | "AND" --and
      | "+" -- plus
      | "for" identifier "in" Expr -- for
      | "else if" Expr -- elseIf
      | "if" Expr -- if
      | "else" -- else
      | Assign
      | PlusEquals
      | MinusEquals
      | DeclareConst
      | DeclareVar
      | PipedLine
      | Labeled
      | StyledComponent
      | Or


    TextBlock =
      | #" "* (#" "+ | TextBlockSegment)*

    TextBlockSegment =
      | "{" Expr "}"  --interpolation
      | ~"\{" any  --text

    StyledComponent =
      | "~" identifier

    Element =
      | "<" TagName Attributes ">" (#" "+ | Child)* "</" TagName? ">"  -- withChildren
      | "<" TagName Attributes "/>"                            -- selfClosing
      | "<" TagName Attributes ">" (#" "+ | Child)* -- onlyOpening
      | "<>" (#" "+ | Child)* "</>"? -- asdf
      
    TagName = #letter+ | ObjectRef
    Attributes = Attribute*
    Attribute = #letter+ ("=" AttributeValue)?      -- pair
    AttributeValue =
      | string -- string
      | "{" Expr "}" -- expr
    // // CodeInsertion = "{" code "}"
    // // code = ~"}" any*  // anything that is not a closing brace
    Child = 
      | Element --element
      | Text -- text
      | "{" Expr "}" -- expr
    // Text = (#alnum | "!".."." | ":"..";" | "?".."@" | "[".."\`")+
    Text = (#alnum | "!".."(" | "*".."." | ":"..";" | "?".."@" | "[".."\`")+
    // | "{".."~")+

    PipedLine = "|" Expr

    Labeled = alnum+ ":" Expr
      
    InterpolatedString =
      | "\`" #" "* (#" "+ | InterpolatedSegment)* "\`"

    InterpolatedSegment =
      | "\${" Expr "}"  --interpolation
      | ~"\${" ~"\`" any  --text
      
    Not =
      | "!" Primary
      
    Negate =
      | "-" Primary

    DeclareConst =
      | identifier ":=" Expr

    Assign =
      | Primary "=" Expr

    DeclareVar =
      | "var" identifier "=" Expr

    PlusEquals =
      | Primary "+=" Expr

    MinusEquals =
      | Primary "-=" Expr
    
    Or =
      | Or "OR" And -- eq
      | And
      
    And =
      | And "AND" Eq --asfd
      | Eq
        
      
    Eq =
      | Eq "==" Ne -- add
      | Ne
      
    Ne =
      | Ne "!=" Lt -- one
      | Lt
      
    Lt =
      | Lt "<" Lte --one
      | Lte
      
    Lte =
      | Lt "<=" Gt --one
      | Gt

    Gt =
      | Lt ">" Gte --one
      | Gte
      
    Gte =
      | Lt ">=" Additive --one
      | Additive
    

    Additive
      =
      | Additive "+" Multiplicative --add
      | Additive "-" Multiplicative --subtract
      | Multiplicative

    Multiplicative
      =
      | Multiplicative "*" Primary --multiply
      | Multiplicative "/" Primary --divide
      | Primary


    Boolean =
      | "TRUE"
      | "FALSE"

    Closure = 
      | identifier spaces "=>" spaces Expr? --singleIdentifier
      | "(" spaces ListOf<identifier, ","> ","? spaces ")" spaces "=>" spaces Expr? --identifierList

    DictionaryEntry = DictionaryKey ":" Expr
    DictionaryKey = identifier | string | ObjectRef --ref
    Dictionary = 
      | "X"? "{" spaces "}" -- emptyDict
      | "X"? "{" spaces ListOf<DictionaryEntry, ","> spaces "}" --dict

    Array =
      | "X"? "[" spaces "]" --emptyArray
      | "X"? "[" spaces ListOf<Expr, ","> spaces "]" --ary

    Primary
      =
      | Element
      | InterpolatedString
      | Primary spaces "?" spaces Primary spaces ":" spaces Primary -- tertiary
      | Closure
      | Dictionary
      | Array
      | Not
      | Negate
      | Boolean
      | integer
      | string
      | FunctionCall
      | "(" Expr ")" --parens
      | Primary "." Accessor? -- accessor
      | Primary "[" Primary "]" -- bracketAccessor
      | ObjectRef
      | identifier
      

    FunctionCall =
      | Primary "(" spaces ")"  -- noArgs
      | Primary "(" spaces ListOf<Expr, ","> spaces ")"  -- withArgs
  
    ExprPadding = 
      spaces Expr spaces

    Accessor = identifier | ObjectRef
    
    identLetter = alnum | "$"
    identifier = identLetter+
    integer = digit+
    string = "\\"" (~"\\"" any)* "\\""

    ObjectRef
      = "_$" content "$_"
      
    content
      = (~"$_" any)*

    space := "\\x00".."\\x20" | "\\n"

  }
`;
