import React, { Component, useEffect, useRef } from 'react';
import logo from './logo.svg';
import './App.css';
import { component, css, styled } from './component';
import { db, initDb, initLocalDb } from './db';
import { X, XInit, XObject } from './XObject';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
  useParams,
  useNavigation,
  useNavigate,
} from "react-router-dom";

import _ from 'lodash';
import axios from 'axios';
import config from './config';

import {  keymap } from '@codemirror/view';
import { defaultKeymap, indentWithTab } from '@codemirror/commands';
import { EditorState } from '@codemirror/state';
import {EditorView, basicSetup } from "codemirror"
import { executeScriptFromData } from './shorthand/executeScriptFromData';
import { ScriptBlock, renderEl } from './shorthand/formula';


const sampleVersion = '2';

const sample =

`state := X{}
  value: "Hello"

array := X["1", "2"]

buttonLabel := "Update State"

Button := ~button
  color: red
  cursor: pointer

App := ~div
  font-family: georgia
  {Button}
    margin-top: 16px

() =>
  <App>
    <div>
      state.value
    <Button>
      | onClick: () =>
        state.value = "World"
      \`\${buttonLabel} (currently \${state.value})\`
    <ul>
      array.map(el => <li key={el}>{el})
      <li>
        <button>
          | style: {"cursor":"pointer"}
          | onClick: () => array.push(array.length + 1)
          \`Add \${array.length + 1} to array\`
`

function getSample() {
  if (localStorage.getItem('codeVersion') != sampleVersion) {
    return sample;
  }
  else {
    return localStorage.getItem('code') || sample;
  }
}



interface Line {
  content?
  children?: Line[]
  parent?
  indentation?
}
function textToNestedStructure(text) {
  const lines = text.split('\n');
  const root: Line = { content: null, children: [] };
  let currentParent = root;
  let prevIndentation = -1;

  for (let line of lines) {
    const indentation = line.search(/\S/);  // Find the first non-space character.
    line = line.trim();
    if (!line) continue;  // Skip empty lines.

    const newItem: any = { content: line, children: [] };

    if (indentation > prevIndentation) {
      // It's a child of the previous line.
      currentParent.children.push(newItem);
    } else {
      // It's a sibling (or a sibling of an ancestor). Move up the parent chain.
      while (indentation <= prevIndentation) {
        currentParent = currentParent.parent;
        prevIndentation = currentParent.indentation;
      }
      currentParent.children.push(newItem);
    }

    // Set up for the next iteration.
    newItem.parent = currentParent;
    newItem.indentation = indentation;
    currentParent = newItem;
    prevIndentation = indentation;
  }

  return root.children;
}

function lineStructureToScriptBlocks(lines: Line[]): ScriptBlock[] {
  return lines.map(line => {
    return {
      data: line.content,
      children: lineStructureToScriptBlocks(line.children),
    }
  })
}

@component
class Render extends Component<{ func }> {
  render() {
    return this.props.func();
  }
}

@component
class Thing extends Component {
  static styles = styled.div`
    .left {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 50%;
    }

    .right {
      position: absolute;
      top: 0;
      right: 0;
      width: 50%;
      bottom: 0;
    }
  `;
  state = XInit(class {
    value = getSample();

  })
  render() {
    const v = this.state.value;
    const r = executeScriptFromData(lineStructureToScriptBlocks(textToNestedStructure(v || '')));

    return (
      <>
      <div className="left">

      <Editor
      defaultValue={v}
      onChange={value => {
        this.state.value = value;
        localStorage.setItem('code', value);
        localStorage.setItem('codeVersion', sampleVersion);
      }} />
      {/* <button
        onClick={() => {
          executeScriptFromData(lineStructureToScriptBlocks(textToNestedStructure(v || '')));
        }}
      >Run</button> */}

      </div>
      <div className="right">
      <Render func={() => _.isFunction(r) ? renderEl(r()) : ''} />
      </div>
      </>
    )
  }
}

export const Editor = ({ defaultValue, onChange }) => {
  const editor = useRef();

  useEffect(() => {
    let sync_val;
    const startState = EditorState.create({
      doc: defaultValue,
      extensions: [
        basicSetup,
        keymap.of([...defaultKeymap, indentWithTab]),
          EditorView.updateListener.of(function(e) {
            onChange(e.state.doc.toString());
              
          })

      ],
    });

    const view = new EditorView({
      state: startState,
      parent: editor.current,
  
    });



    return () => {
      view.destroy();
    };
  }, []);

  return <div ref={editor}></div>;
};

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <Thing />
      </>
    )

  },

]);

@component
class App extends Component {
  static styles = styled.div`

  `;


  render() {
    return (
      <>
        <RouterProvider router={router} />
      </>
    )
  }
}

export default App;
