
interface Handler {
  test
  perform
}

export function registerFormulaAccessorHandler(handler: Handler) {
  formulaAccessorHandlers.push(handler);
}

export const formulaAccessorHandlers: Handler[] = [];


export const primitiveHandlers: Handler[] = [];
export function registerFormulaPrimiterEvaluators(handler: Handler) {
  primitiveHandlers.push(handler);
}


export const dictKeyHandlers: Handler[] = [];
export function registerFormulaDictKeyEvaluators(handler: Handler) {
  dictKeyHandlers.push(handler);
}


export const callHandlers: Handler[] = [];
export function registerFormulaCallHandler(handler: Handler) {
  callHandlers.push(handler);
}



export const objRefHandlers: Handler[] = [];
export function registerFormulaObjectRefResolver(handler: Handler) {
  objRefHandlers.push(handler);
}