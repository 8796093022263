import * as ohm from 'ohm-js';
import _ from 'lodash';
import { styled } from '../component';
import React from 'react';
import { X, XObject, x } from '../XObject';
import { callHandlers, formulaAccessorHandlers, objRefHandlers, primitiveHandlers } from './registerFormulaAccessorHandler';
import { grammarDef } from './grammarDef';
import { expandToText } from '../richTextHelpers';

export interface Hooks {
  resolveObjectRef?
  accessorHandlers?: {
    test
    perform
  }[]
  types?
}

export function renderEl(el) {
  if (el instanceof El) {
    const attributes = {};
    try {
      if (el.attributes) for (let [name, value] of el.attributes) {
        if (name == 'class') name = 'className';
        attributes[name] = value;
      }
    }
    catch (E) {

    }

    
    let hasChildren = true;
    if (el.tag == 'br' || el.tag == 'hr') {
      hasChildren = false;
    }


    return React.createElement(el.tag || 'span', attributes, hasChildren ? el.children.map(c => renderEl(c)) : undefined);
  }
  else if (_.isArray(el)) {
    return el.map(x => renderEl(x))
  }
  else if (_.isString(el) || _.isNumber(el)) {
    return el;
  }
}


enum GrammarType {
  Expr = 'Expr',
  Not = 'Not',
  Negate = 'Negate',
  Or = 'Or',
  And = 'And',
  Eq = 'Eq',
  Ne = 'Ne',
  Lt = 'Lt',
  Lte = 'Lte',
  Gt = 'Gt',
  Gte = 'Gte',
  Additive = 'Additive',
  Multiplicative = 'Multiplicative',
  Primary = 'Primary',

  identifier = 'identifier',
  integer = 'integer',
  string = 'string',
}

export const grammar = ohm.grammar(grammarDef);

let _globals;
export function setGlobals(gloabls) {
  _globals = gloabls;
}


let _types = {};
export function setBaseTypes(types) {
  _types = types;
}

function evalPrimitive(node) {
  const r = node.eval();
  for (const handler of primitiveHandlers) {
    if (handler.test(r)) return handler.perform(r);
  }

  return r;
}

function compileStyles(blocks: ScriptBlock[], hooks: Hooks, scope, indent=0) {
  const lines = [];

  for (const block of blocks) {
    const formula = expandFormula(block.data, hooks);
    const line = execFormula(formula, scope, hooks, 'TextBlock');

    if (block.children?.length) {
      lines.push(
`${line} {
  ${compileStyles(block.children, hooks, scope, indent+1)}
}`
        )
    }
    else {
      lines.push(line + ';');
    }
  }

  return lines.join('\n');
}

const typeSemantics = grammar.createSemantics().addOperation('eval', {
  identifier(a) {
    return 'identifier';
  },
  ObjectRef(a, b, c) {
    return 'ObjectRef';
  },
  string(a, b, c) {
    return 'string';
  },
  Expr_else(a) {
    return 'Else';
  },
  Expr_if(a, b) {
    return 'If';
  },
  Assign(a, b, c) {
    return 'Assign';
  },
  Expr_or(a) {
    return 'OR';
  }
});


const evalSemantics = grammar.createSemantics().addOperation<any>('eval', {
  identifier(a) {
    return ['Expr', a.sourceString];
  },
  ObjectRef(a, b, c) {
    return ['Expr'];
  },
  string(a, b, c) {
    return ['Expr'];
  },
  integer(a) {
    return ['Expr'];
  },
  Expr_else(a) {
    return ['Else'];
  },
  Expr_if(a, b) {
    return ['If', b.sourceString];
  },
  Expr_or(a) {
    return ['OR'];
  },
  Expr_elseIf(a, b) {
    return ['Else If', b.sourceString];
  },
  _iter(...children) {
    return children.map(c => c.eval());
  },
  NonemptyListOf(a, b, c) {
    return [ a.eval() ].concat(c.eval());
  },
  EmptyListOf() {
    return [];
  },
  Assign(a, b, c) {
    return ['Assign', a.sourceString, c.sourceString];
  },
  InterpolatedString(a, b, c, d) {
    return ['Expr'];
  },
  PlusEquals(a, b, c) {
    return ['PlusEquals', a.sourceString, c.sourceString];
  },
  MinusEquals(a, b, c) {
    return ['MinusEquals', a.sourceString, c.sourceString];
  },
  DeclareConst(identifier, _op, expr) {
    return ['DeclareConst', identifier.sourceString, expr.sourceString];
  },
  DeclareVar(_var, identifier, _op, expr) {
    return ['DeclareVar', identifier.sourceString, expr.sourceString];
  },
  Expr_comment(_op, comment) {
    return ['Comment', comment.sourceString];
  },
  FunctionCall_withArgs(a, b, c, d, e, f) {
    return ['Expr'];
  },
  Closure_singleIdentifier(arg, _a, _b, _c, expr) {
    if (expr.sourceString) {
      return ['Expr'];
    }
    else {
      return ['Closure', [arg.sourceString]];
    }
  },
  Closure_identifierList(a, b, identifiers, c, d, e, f, g, h, expr) {
    if (expr.sourceString) {
      return ['Expr'];
    }
    else {
      return ['Closure', identifiers.eval().map(x => x[1])];
    }
  },
  Additive_add(a, b, c) {
    return ['Expr'];
  },
  Dictionary_emptyDict(x, a,b,c) {
    return ['Expr'];
  },
  Dictionary_dict(x, a, b, c, d, e) {
    return ['Expr'];
  },
  Labeled(a, b, c) {
    return ['Labeled', a.sourceString, c.sourceString];
  },
  Array_ary(x, a, b, c, d, e) {
    return ['Expr'];
  },
  PipedLine(a, b) {
    return ['PipedLine', b.sourceString];
  },
  Element_onlyOpening(a, b, c, d, e) {
    return ['Expr'];
  },
  Element_asdf(a, b, c) {
    return ['Expr'];
  },
  Element_withChildren(a, b, c, d, e, f, g, h) {
    return ['Expr'];
  },
  StyledComponent(a, identifier) {
    return ['StyledComponent', identifier.sourceString];
  },
  Primary_accessor(a, b, c) {
    return ['Expr'];
  }
});


export interface ScriptBlock {
  children: ScriptBlock[]
  data: string | [string, any[]][]
}

class Pass {}

export function executeScript(script: ScriptBlock[], hooks: Hooks={}, parentScope={}, scopeInit={}) {
  const scope = {
    ...parentScope,
    ...scopeInit,
  };

  let lastVal;
  for (let i = 0; i < script.length; ++ i) {
    const block = script[i];

    const formula = expandFormula(x(block.data), hooks);

    const doRoot = formula => {
      let lastVal;

      if (!formula) return new Pass;
  
      try {
        const evalInfo = getEvalInfo(formula);
  
        const getExpr = str => {
          if (block.children?.length) {
            const value = execFormula(str, scope, hooks);
            if (_.isFunction(value)) {
              const args = [];
              for (const argBlock of block.children) {
                args.push(executeScript([argBlock], hooks, scope));
              }
              return value(...args);
            }
            else if (_.isPlainObject(value)) {
              for (const argBlock of block.children) {
                const entry = executeScript([argBlock], hooks, scope);
                if (_.isArray(entry)) {
                  value[entry[0]] = entry[1];
                }
              }
              return value;
            }
            else if (value instanceof El) {
              for (const argBlock of block.children) {
                const entry = executeScript([argBlock], hooks, scope);
                if (entry instanceof Meta) {
                  value.attributes.push(entry.value);
                }
                else if (!_.isNil(entry)) {
                  value.children.push(entry);
                }
              }
              return value;
            }
            else {
              return value;
            }
          }
          else {
            return execFormula(str, scope, hooks);
          }
        }
  
        const getVal = (str) => {
          const valueEvalInfo = getEvalInfo(str, true);
          if (valueEvalInfo[0] == 'Expr') {
            return getExpr(str);
          }
          else if (valueEvalInfo[0] == 'Closure') {
            return (...args) => {
              const argScope = {};
              for (let i = 0; i < valueEvalInfo[1].length; ++ i) {
                argScope[valueEvalInfo[1][i]] = args[i];
              }
              return executeScript(block.children || [], hooks, scope, argScope);
            }
          }
          else if (valueEvalInfo[0] == 'StyledComponent') {
            return styled(valueEvalInfo[1])`${compileStyles(block.children, hooks, scope)}`;
          }
          else {
            console.log(str, valueEvalInfo);
          }
        }
  
        if (evalInfo[0] == 'DeclareVar') {
          scope[evalInfo[1]] = getVal(evalInfo[2]);
        }
        else if (evalInfo[0] == 'DeclareConst') {
          scope[evalInfo[1]] = getVal(evalInfo[2]);
        }
        else if (evalInfo[0] == 'PlusEquals') {
          scope[evalInfo[1]] += getVal(evalInfo[2]);
        }
        else if (evalInfo[0] == 'MinusEquals') {
          scope[evalInfo[1]] -= getVal(evalInfo[2]);
        }
        else if (evalInfo[0] == 'Assign') {
          const parts = evalInfo[1].split('.');
          if (parts.length == 1) {
            scope[evalInfo[1]] = getVal(evalInfo[2]);
          }
          else if (parts.length == 2) {
            scope[parts[0]][parts[1]] = getVal(evalInfo[2]);
          }
        }
        else if (evalInfo[0] == 'Comment') {
          lastVal = new Pass();
        }
        else if (evalInfo[0] == 'Closure') {
          return (...args) => {
            const argScope = {};
            for (let i = 0; i < evalInfo[1].length; ++ i) {
              argScope[evalInfo[1][i]] = args[i];
            }
            return executeScript(block.children || [], hooks, scope, argScope);
          }
        }
        else if (evalInfo[0] == 'Expr') {
          lastVal = getExpr(formula);
        }
        else if (evalInfo[0] == 'Labeled') {
          lastVal = [ evalInfo[1], getVal(evalInfo[2]) ];
        }
        else if (evalInfo[0] == 'PipedLine') {
          lastVal = new Meta(doRoot(evalInfo[1]));
        }
        else if (evalInfo[0] == 'If' || evalInfo[0] == 'Else If') {
          if (execFormula(evalInfo[1], scope, hooks)) {
            lastVal = executeScript(block.children, hooks, scope);
            while (i < script.length) {
              ++i;
              const evalInfo = getEvalInfo(expandFormula(x(script[i].data)), true);
              if (evalInfo[0] != 'Else If' && evalInfo[0] != 'Else') {
                --i;
                break;
              }
            }
          }
        }
        else if (evalInfo[0] == 'Else') {
          lastVal = executeScript(block.children, hooks, scope);
        }
        else if (evalInfo[0] == 'StyledComponent') {
          lastVal = styled(evalInfo[1])`${compileStyles(block.children, hooks, scope)}`;
        }

        else {
          console.log('[executeScript] not handled', formula, evalInfo);
        }
      }
      catch (e) {
        console.log('[executeScript] failed', formula, e);
      }

      return lastVal;
    }

    const r = doRoot(formula);
    if (!(r instanceof Pass)) {
      lastVal = r;
    }
  }

  // console.log('[executeScript] scope', scope);

  // console.log('[executeScript] lastVal', lastVal);

  return lastVal;
}

export function getType(str) {
  return typeSemantics(grammar.match(str)).eval();
}

class Meta {
  constructor(public value) {}
}

class El {
  constructor(public tag, public attributes, public children=[]) {}
}

export function getEvalInfo(str, catchError=false) {
  if (catchError) {
    try {
      return evalSemantics(grammar.match(str)).eval();
    }
    catch (e) {
      return ['Error', e];
    }
  }
  else {
    return evalSemantics(grammar.match(str)).eval();
  }
}

const cache = {};
export function execFormula(input, env, hooks:Hooks={}, startRule?) {
  const scopes = [];
  const semantics = grammar.createSemantics().addOperation('eval', {
    Not(a, b) {
      return !evalPrimitive(b);
    },
    [GrammarType.Expr](a) {
      return a.eval();
    },
    [GrammarType.Additive + '_add'](a, _, b) {
      return a.eval() + b.eval();
    },
    Boolean(a) {
      return a.sourceString == 'TRUE';
    },
    [GrammarType.Additive + '_subtract'](a, _, b) {
      return a.eval() - b.eval();
    },
    Assign(a, b, c) {
      return c.eval();
    },
    PlusEquals(a, b, c) {
      return c.eval();
    },
    Closure_identifierList(a, b, c, d, e, f, g, h, i, j) {
      return (...args) => {
        const scope = {};
        for (let i = 0; i < args.length && i < c.children.length; ++ i) {
          scope[c.children[i].sourceString] = args[i];
        }
        scopes.push(scope);
        const [r] = j.eval();
        scopes.pop();
        return r;
      }
    },
    Closure_singleIdentifier(ident, b, c, d, expr) {
      return (...args) => {
        const scope = {};
        scope[ident.sourceString] = args[0];
        scopes.push(scope);
        const [r] = expr.eval();
        scopes.pop();
        return r;
      }
    },
    Dictionary_dict(x, a, b, entries, e, f) {
      const dict = {};
      for (let [key, value] of entries.eval()) {
        for (const handler of primitiveHandlers) {
          if (handler.test(key)) {
            key = handler.perform(key);
          }
        }
        
        dict[key] = value;
      }

      return x.eval().length ? X(dict): dict;
    },
    TextBlock(b, c) {
      return b.eval().join('') + c.eval().map(i => _.isArray(i) ? i.join('') : i).join('');
    },
    TextBlockSegment_interpolation(a, b, c) {
      return b.eval();
    },
    TextBlockSegment_text(a) {
      return a.eval();
    },
    Dictionary_emptyDict(x, a, b, c) {
      return x.eval().length ? X({}):  {};
    },

    Array_ary(x, a, b, c, d, e) {
      const array = c.eval();
      return x.eval().length ? X(array) : array;
    },
    Expr_for(a, b, c, d) {

    },
    Child_expr(a, expr, b) {
      return expr.eval();
    },
    DictionaryEntry(a, b, c) {
      // console.log('DictionaryEntry', {
      //   a: a.eval(),
      //   b: b.eval(),
      //   c: c.eval(),
      // })

      return [a.eval(), c.eval()];
    },
    Ne_one(a, b, c) {
      return a.eval() != c.eval();
    },
    [GrammarType.Or + '_eq'](a, _, b) {
      console.log('poop', evalPrimitive(a), evalPrimitive(b));
      return evalPrimitive(a) || evalPrimitive(b);
    },
    [GrammarType.Eq + '_add'](a, _, b) {
      return a.eval() == b.eval();
    },
    integer: (a) => {
      return parseInt(a.sourceString);
    },
    Primary_parens(_lp, exp, _rp) {
      return exp.eval();
    },
    FunctionCall_withArgs(expr, b, c, args, e, f) {
      const func = expr.eval();
      // console.log('FunctionCall_withArgs', func);

      for (const handler of callHandlers) {
        if (handler.test(func)) {
          return handler.perform(func, env, args.eval());
        }
      }

      // if (func instanceof FormulaObjectWrapper) {
      //   return func.call(env, ...args.eval());
      // }
      // else {
        if (_.isFunction(func)) {
          return func(...args.eval());
        }
        else {
          console.error('not a func', func, expr.sourceString);
        }
      // }
    },
    FunctionCall_noArgs(expr, a, b, c) {
      const func = expr.eval();
      for (const handler of callHandlers) {
        if (handler.test(func)) {
          return handler.perform(func, env);
        }
      }

      // if (func instanceof FormulaObjectWrapper) {
      //   return func.call(env);
      // }
      // else {
        if (_.isFunction(func)) {
          return func();
        }
        else {
          console.error('not a func', func, expr.sourceString);
        }
      // }
    },
    And_asfd(a, b, c) {
      return a.eval() && c.eval();
    },
    ObjectRef(a, b, c) {
      const [type, id] = b.sourceString.split(':');

      if (hooks.resolveObjectRef) {
        const resolvedObj = hooks.resolveObjectRef?.({ type, id });
        if (resolvedObj != 'e801f1d3-cce2-548f-8253-4d3ed5f035ed') return resolvedObj;  
      }
      for (const handler of objRefHandlers) {
        if (handler.test({ type, id })) {
          return handler.perform({ type, id }, env.base);
        }
      }
      // return new FormulaObjectWrapper({
      //   type: type as any,
      //   id,
      // }, env.base)

    },
    identifier(a) {

      const identifier = a.sourceString;
  
      const scope = {
        ...env,
        ...(_globals?.({ env }) || {}),
        unwrapX(args) {
          return x(args);
        },
        JsonStringify(args) {
          return JSON.stringify(args);
        },
        Concat: (...args) => {
          let str = '';
          for (const a of args) {
            // if (a instanceof FormulaObjectWrapper) {
            //   str += a.get('ToString')?.();
            // }
            // else {
              str += a;
            // }
          }
          return str;
        },

        Average: values => {
          let total = 0;
          for (const value of values) {
            total += value;
          }
          return total/values.length;
        },
        Debug: (...values) => {
          console.log('[formula]', ...values);
        },
        Sum: (args) => {
          return _.sum(args.map(x => window.parseFloat(x) || 0));
        },
        Max: (...args) => {
          if (_.isArray(args[0])) {
            return _.max(args[0].map(x => window.parseFloat(x) || 0));
          }
          else {
            return _.max(args.map(x => window.parseFloat(x) || 0));
          }
        },
        Min: (...args) => {
          if (_.isArray[0]) {
            return _.min(args[0].map(x => window.parseFloat(x) || 0));
          }
          else {
            return _.min(args.map(x => window.parseFloat(x) || 0));

          }
        },
        Now() {
          return new Date();
        },


        log(...args) {
          console.log('[FORMULA][log]', ...args);
        },



      }

      for (let i = scopes.length - 1; i >= 0; -- i) {
        if (identifier in scopes[i]) {
          return scopes[i][identifier];
        }
      }
  
      return scope[identifier];
    },
    _iter(...children) {
      return children.map(c => c.eval());
    },
    NonemptyListOf(a, b, c) {
      return [ a.eval() ].concat(c.eval());
    },
    string(a, b, c) {
      return b.sourceString;
      // console.log({
      //   a: a.sourceString,
      //   b: b.sourceString,
      //   c: c.sourceString,
      // })
    },
    _terminal() {
      return this.sourceString;
    },
    Gt_one(a, b, c) {
      return null;
    },
    Primary_accessor(a, __, b) {
      const obj = a.eval();
      let prop = b.sourceString;
      console.log(obj, prop, XObject.isArray(obj));

      if (_.isNil(obj)) {
        console.log('asdf accessing nil', a.sourceString, b.sourceString);
      }
      else if (_.isArray(obj) || XObject.isArray(obj)) {
        prop = prop.toLowerCase();
        if (prop == 'map') {
          return func => obj.map(func);
        }
        else if (prop == 'push') {
          return func => obj.push(func);
        }
        else if (prop == 'filter') {
          return func => obj.filter(func);

          // return ([params, expr]) => {
          //   return obj.filter(v => {
          //     scopes.push({
          //       [params.children[0].sourceString]: v,
          //     });
          //     const r = expr.eval();
          //     scopes.pop();
          //     return r;
          //   })
          // }
        }
        else if (prop == 'length') {
          return obj.length;
        }
      }
      else if (_.isPlainObject(obj)) {
        return obj[prop];
      }

      else if (_.isString(obj)) {
        if (prop == 'Length') {
          return obj.length;
        }
      }
      else {
        for (const handler of (hooks.accessorHandlers || []).concat(formulaAccessorHandlers)) {
          if (handler.test(obj)) {
            const propType = getType(prop);
            if (propType == 'identifier') {
              return handler.perform(obj, prop);
            }
            else {
              return handler.perform(obj, b.eval()[0]);
            }
          }
        }
      }
      return null;
    },
    Primary_bracketAccessor(a, __, b, ___) {
      const obj = a.eval();
      const prop = b.eval();
      // console.log('Primary_bracketAccessor', obj, prop);
      // if (obj instanceof FormulaObjectWrapper || obj instanceof FormulaObjectProxy) {
      //   return obj.get(prop);
      // }
      for (const handler of (hooks.accessorHandlers || []).concat(formulaAccessorHandlers)) {
        if (handler.test(obj)) {
          return handler.perform(obj, prop);
        }
      }
    },
    Primary_tertiary(condition, _s1, _q, _s2, first, _s3, _c, _s4, second) {
      // if (!condition.eval()) {
      //   console.log('asdf tertiary', second.sourceString, second.eval());
      // }
      return condition.eval() ? first.eval() : second.eval();
    },
    InterpolatedString(a, b, c, d) {
      return b.eval().join('') + c.eval().map(i => _.isArray(i) ? i.join('') : i).join('');
    },
    InterpolatedSegment_interpolation(a, b, c) {
      return b.eval();

    },
    InterpolatedSegment_text(a) {
      return a.eval();
    },
    Element_onlyOpening(a, tagName, attributes, b, children) {

      let tag;
      if (tagName.sourceString.startsWith('_$')) {
        tag = tagName.eval();
      }
      else if (tagName.sourceString[0] == tagName.sourceString[0].toUpperCase()) {
        tag = env[tagName.sourceString];
      }
      else {
        tag = tagName.sourceString
      }

      return new El(tag, attributes.eval(), children.eval());
    },
    Element_withChildren(a, tagName, attributes, aa, children, b, c, d) {
      let tag;
      if (tagName.sourceString.startsWith('_$')) {
        tag = tagName.eval();
      }
      else if (tagName.sourceString[0] == tagName.sourceString[0].toUpperCase()) {
        tag = env[tagName.sourceString];
      }
      else {
        tag = tagName.sourceString
      }

      return new El(tag, attributes.eval(), children.eval());
    },
    Element_asdf(a, children, c) {
      return children.eval();
    },
    Attribute_pair(name, a, value) {
      return [name.sourceString, value.eval()[0]];
    },
    AttributeValue_expr(a, expr, b) {
      return expr.eval();
    },
  })
  
  try {
    const match = (input in cache) ? cache[input] : (cache[input] = grammar.match(input, startRule));
    return semantics(match).eval();
  }
  catch (e) {
    console.log(e);
    return 'error';
  }
}
export function expandFormula(input, hooks: Hooks = {}) {
  return expandToText({
    types: {
      ..._types,
      ...(hooks.types || {}),
    }
  }, input)['replace'](/[^\u0000-\u007E]/g, " ");
}
